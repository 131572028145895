<template>
  <div class="app flex-row align-items-center" id="login-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <h3 class="text-center">Quên mật khẩu</h3>
                <b-alert
                  variant="danger"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlert"
                  @dismissed="mData.dataDefault.showDismissibleAlert=false"
                >{{mData.dataDefault.error}}</b-alert>
                <b-alert
                  variant="success"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlertSuccess"
                  @dismissed="mData.dataDefault.showDismissibleAlertSuccess=false"
                >{{mData.dataDefault.success}}</b-alert>

                <el-form
                  :model="mData.dataForm"
                  :rules="mData.validForm"
                  ref="myForm"
                  label-width="100%"
                  label-position="top"
                  @submit.native.prevent
                >
                  <el-form-item label="Nhập tài khoản để lấy lại mật khẩu" prop="account">
                    <el-input
                      prefix-icon="el-icon-user"
                      v-model.trim="mData.dataForm.account"
                      clearable
                    ></el-input>
                  </el-form-item>

                  <el-form-item class="text-center">
                    <el-button
                      type="primary"
                      @click="fn_submitForm()"
                      v-ladda
                      native-type="submit"
                    >Gửi mã khôi phục</el-button>
                  </el-form-item>
                </el-form>
                <b-col md="12" class="text-center">
                  <b-link to="/auth/login">Quay lại trang đăng nhập</b-link>
                </b-col>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthService from "../../service/AuthService";
import LanguageService from "../../service/LanguageService";
import ValidService from "../../service/ValidService";
import MethodService from "../../service/MethodService";
export default {
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        dataDefault: {
          error: "",
          showDismissibleAlert: false,
          success: "",
          showDismissibleAlertSuccess: false
        },
        dataForm: {
          account: ""
        },
        validForm: {
          account: [ValidService.required]
        }
      }
    };
  },
  methods: {
    fn_submitForm() {
      this.$refs["myForm"].validate(valid => {
        if (valid) {
          AuthService.recoverPassword(this.mData.dataForm.account)
            .then(res => {
              // this.mData.dataDefault.showDismissibleAlertSuccess = true;
              // this.mData.dataDefault.success = "Bạn hãy kiểm tra email để lấy lại mật khẩu";
              this.$router.push({
                name: "Auth-ResetPassword",
                query: { account: this.mData.dataForm.account }
              });
            })
            .catch(error => {
              this.mData.dataDefault.showDismissibleAlert = true;
              this.mData.dataDefault.error =
                "Gửi mã khôi phục không thành công";
            });
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
    }
  },
  mounted: function() {}
};
</script>
<style>
.bg-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bg-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
